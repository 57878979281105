<template>
  <div class="secondary-lighten rounded">
    <image-carousel :ad="ad">
      <template v-if="adTypeIsHtml5" #default="{ slide }">
        <div class="overflow-auto iframe-container d-flex justify-center">
          <iframe
            :src="slide"
            class="secondary"
            frameBorder="0"
            :width="iframeSize.width"
            :height="iframeSize.height"
          />
        </div>
      </template>
    </image-carousel>
  </div>
</template>

<script>
  import { adTypes } from '@clickadilla/components/constants/ad-types.js'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import ImageCarousel from '@/components/ads/ImageCarousel.vue'
  import Ads from '@/services/classes/Ads.js'

  export default {
    name: 'BannerCreativePreview',
    components: {
      ImageCarousel
    },
    props: {
      ad: {
        type: Ads,
        required: true
      }
    },
    data() {
      return {
        hasCreativeImage: true,
        image: null
      }
    },
    computed: {
      ...mapGetters('settings', ['getAdFormatsByAdNetwork']),
      adTypeIsHtml5() {
        return this.ad.model.type === adTypes.HTML5
      },
      iframeSize() {
        const [width, height] = this.ad.size.split('x')
        return { width, height }
      }
    },
    created() {
      this.hasCreativeImage = this.ad.model.creatives.some((creative) => creative.image.src)
      if (!this.hasCreativeImage) {
        this.fetchImage()
      }
    },
    methods: {
      async fetchImage() {
        const iconUrl = this.getAdFormatsByAdNetwork.find((format) => (format.type === this.ad.adFormatType
          && format.size === this.ad.size))?.icon
        try {
          const adFormatIcon = await axios(iconUrl)
          this.image = adFormatIcon.data
        } catch {
          this.image = ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .iframe-container {
    height: 190px;
    width: 100%;
  }
</style>
